import React, { useContext } from "react";
import { toast } from "react-toastify";

import { ProductsContext } from "../contexts";
import { ProductShop } from "./useShop";
import { User } from "./useUser";
import { getProducts } from "../services/products";
import productsCache from "../cache/products";
import service from "../services/products";
import storage from "../db/image";
import { funcs } from "../utils";

interface Item {
  _id: string;
  icon?: React.JSX.Element;
  label: string;
  onClick?: () => void;
  rightIcon?: React.JSX.Element;
  route?: string;
}

export interface ProductType extends Item {}

export interface View {
  _id: string;
  viewer: string;
  timestamp: number;
}

export interface Product {
  _id: string;
  activityId?: string;
  author: User;
  description: string;
  images: string[];
  isNegotiable?: boolean;
  name: string;
  price: number;
  shop: ProductShop;
  timestamp: number;
  type: ProductType;
  views?: View[];
}

export default () => {
  const { products, setProducts, isLoading } = useContext(ProductsContext);

  const deleteProductById = async (productId: string) => {
    const initial = [...products];
    let found: Product | undefined;

    toast.loading("Deleting product...");
    setProducts(
      initial.filter((p) => {
        if (p._id === productId) found = p;

        return p._id !== productId;
      })
    );

    const { ok, problem } = await service.deleteProductBy(productId);
    toast.dismiss();

    let error = problem || "Product deletion terminated unsuccessfully!";
    if (!ok) {
      setProducts(initial);
      toast.error(error);
    } else {
      storage.deleteImages(found?.images || []);
      toast("Product deleted succesfully!");
    }

    return { ok, error };
  };

  const findById = (id: string | undefined) =>
    products.find((p) => p._id === id);

  const findByIdAndUpdate = (id: string, update: Product) => {
    const updated = products.map((p) => (p._id === id ? update : p));

    setProducts(updated);
  };

  const addProduct = (newProduct: Product) =>
    setProducts([newProduct, ...products]);

  return {
    addProduct,
    deleteProductById,
    findById,
    findByIdAndUpdate,
    products,
    isLoading,
  };
};

export const prepareProducts = async (
  products: Product[],
  onLoading: (loading: boolean) => void,
  setProducts: (products: Product[]) => void
) => {
  const cachedProducts = productsCache.get();

  const foundCachedProducts = Boolean(cachedProducts.length);
  if (foundCachedProducts)
    setProducts(funcs.shuffleArray<Product>(cachedProducts));

  if (!foundCachedProducts) onLoading(true);
  const data = await getProducts();
  if (!foundCachedProducts) onLoading(false);

  if (!products.length || data.length !== products.length) {
    setProducts(funcs.shuffleArray<Product>(data));
    productsCache.store(data);
  }
};
