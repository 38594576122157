import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStreamContext } from "react-activity-feed";
import { Activity } from "getstream";
import { FaEdit, FaHandshake, FaTrash } from "react-icons/fa";
import { HiHome, HiUser } from "react-icons/hi";
import { toast } from "react-toastify";

import {
  CopyUrlButton,
  HorizontalProductList,
  Modal,
  ProductUpdateForm,
  Slider,
  TextArea,
} from "../components";
import { emptyProduct } from "../utils/empty";
import { funcs } from "../utils";

import {
  ActivityActor,
  Activity as ProductActivity,
} from "../components/products/Activity";
import {
  useOrders,
  useProduct,
  useProductActivity,
  useReload,
  useReview,
  useUser,
} from "../hooks";
import ChatButtons from "../components/ChatButtons";
import LoadingIndicator from "../components/LoadingIndicator";
import SellerWhatsAppNumberInput from "../components/SellerWhatsAppNumberInput";
import service from "../services/products";
import useCart from "../hooks/useCart";
import useProducts, { Product } from "../hooks/useProducts";
import Review from "../components/products/review/Review";

const MAX_AUTO_ORDER_TRIALS = 3;

const ProductDetailsPage = () => {
  const [activity, setActivity] = useState<Activity>();
  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isEditingProduct, setIsEditingProduct] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [orderPlacementTrials, setOrderPlacementTrial] = useState(0);
  const [review, setReview] = useState("");
  const [sendingReview, setSendingReview] = useState(false);
  const [takingMessage, setTakingMessage] = useState(false);
  const { client } = useStreamContext();
  const { createProductActivity } = useProductActivity();
  const { createReview } = useReview();
  const { info, request } = useReload<Product>(
    null,
    { ...emptyProduct, paramsId: "productId" },
    service.getProductFromServer
  );
  const { product: savedProduct } = useProduct();
  const { productId } = useParams();
  const { user } = useUser();
  const cart = useCart();
  const helper = useProducts();
  const navigate = useNavigate();
  const ordersHelper = useOrders();

  const product = savedProduct || info;
  const productInCart = cart.hasProduct(productId || "");
  const currentUserIsTheSeller = user?._id === product?.shop?.author;
  const productActivity: ProductActivity | undefined =
    activity as unknown as ProductActivity;
  const reviewsCount = productActivity?.reaction_counts?.review || 0;

  useEffect(() => {
    const prepareProducts = async () => {
      if (!savedProduct) request();
    };

    setLoading(true);
    prepareProducts();
    setLoading(false);
    funcs.scrollToTop();

    if (!product.activityId && product)
      toast.warning("You can't review this product yet!");
  }, [productId, product?._id, currentImageIndex, product?.shop?._id]);

  useEffect(() => {
    const fetchProductActivity = async () => {
      try {
        if (activity) return;

        if (product && !product.activityId && user?.isAdmin) {
          const res = await createProductActivity(product);
          if (!res) return;

          window.location.reload();
        }

        if (!product.activityId) return;

        const res = await client?.getActivities({
          ids: [product.activityId],
          enrich: true,
          ownReactions: true,
          withOwnReactions: true,
          withOwnChildren: true,
          withReactionCounts: true,
          withRecentReactions: true,
        });
        if (res?.results) setActivity(res?.results[0] as unknown as Activity);
      } catch (error) {}
    };

    fetchProductActivity();
  }, [product.activityId]);

  useEffect(() => {
    const updateUserInfoWhenNecessary = async () => {
      try {
        const updateNeeded =
          user &&
          (client?.currentUser as unknown as ActivityActor).data.name ===
            "Unknown";

        if (updateNeeded)
          await client?.currentUser?.update({
            ...user,
            username: user.name.replace(" ", "").toLowerCase(),
          });
      } catch (error) {}
    };

    updateUserInfoWhenNecessary();
  }, [client]);

  const updateCart = () => {
    if (!productId)
      return toast.error(
        "App error! Product couldn't be added to ( or removed from) cart"
      );

    productInCart ? cart.remove(productId) : cart.add(productId);
  };

  const deleteProduct = async () => {
    if (!productId) return;

    const { ok } = await helper.deleteProductById(productId);
    if (ok) navigate("/");
  };

  const handleImageClick = (index: number) => setCurrentImageIndex(index);

  const navigateToShop = () =>
    navigate(`/mart/shops/${funcs.convertShopNameToUrl(product?.shop?.name)}`);

  const filteredProducts = (filterFn: (p: Product) => boolean) =>
    helper.products.filter(filterFn).filter(excludeTheDisplayed);

  const excludeTheDisplayed = (p: Product) => p._id !== productId;

  const ofTheSameType = (p: Product) => p.type?._id === product?.type?._id;

  const excludeTheOnesWithSimilarName = (p: Product) =>
    p.name.toLowerCase() !== product?.name?.toLowerCase();

  const relatedProducts = filteredProducts(
    (p) =>
      p.type?._id === product?.type?._id &&
      p.name.toLowerCase().includes(product?.name?.toLowerCase())
  );

  const samePriceProducts = filteredProducts(
    (p) => ofTheSameType(p) && excludeTheOnesWithSimilarName(p)
  );

  const orderNow = async () => {
    setTakingMessage(false);
    setOrderPlacementTrial((trial) => trial + 1);

    const success = await ordersHelper.makeSingleProductOrder(product, message);
    if (!success && orderPlacementTrials < MAX_AUTO_ORDER_TRIALS)
      await orderNow();

    if (!success && orderPlacementTrials >= MAX_AUTO_ORDER_TRIALS)
      toast.error("Something went wrong! Order couldn't be placed");
  };

  const submitReview = async () => {
    try {
      if (!product.activityId || !activity)
        return toast.error("You cannot review this product at the moment");

      setSendingReview(true);
      await createReview(review, activity);
      setSendingReview(false);
      setReview("");
    } catch (error) {
      toast.error("Review not sent");
    }
  };

  return (
    <LoadingIndicator isLoading={loading || !product?._id}>
      <section className="p-4">
        <Modal
          content="Are you sure you want to delete this product permanently?"
          isOpen={confirmDeletion}
          onClose={() => setConfirmDeletion(false)}
          title="Product Deletion"
          primaryBtnLabel="Yes, Delete"
          secondaryBtnLabel="No, Cancel"
          onPrimaryBtnClick={deleteProduct}
        />
        <Modal
          content={
            <ProductUpdateForm
              {...product}
              onDone={() => setIsEditingProduct(false)}
            />
          }
          isOpen={isEditingProduct}
          onClose={() => setIsEditingProduct(false)}
          title="Product Editing"
        />
        <Modal
          content={
            <TextArea
              placeholder="Attach order message to your order (optional)"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          }
          isOpen={takingMessage}
          onClose={() => setTakingMessage(false)}
          title="Attach Message"
          primaryBtnLabel="Proceed"
          onPrimaryBtnClick={orderNow}
        />

        <article className="container mx-auto">
          <section className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Slider
              images={product?.images}
              initialIndex={currentImageIndex}
              onIndexChange={setCurrentImageIndex}
            />

            <article className="col-span-1">
              <div className="mr-3 flex mb-2">
                {product.images.length > 1 &&
                  product.images.map((image, index) => (
                    <img
                      key={image}
                      src={image}
                      alt={`Product Image ${index + 1}`}
                      onClick={() => handleImageClick(index)}
                      className={`w-20 h-20 rounded-md mr-3 object-cover cursor-pointer ${
                        index === currentImageIndex
                          ? "border-blue-500 border-2"
                          : ""
                      }`}
                    />
                  ))}
              </div>

              <div className="flex items-center">
                <p className="text-2xl font-bold text-white-800 mb-2">
                  {product?.name}
                </p>
                <button className="btn btn-ghost btn-xs btn-outline btn-accent ml-2">
                  {product?.type?.label}
                </button>
              </div>

              <p>{product?.description}</p>

              <p className="text-2xl font-bold text-white-800 text-center mt-4">
                Ksh {funcs.addComma(product.price)}
              </p>

              {product?.isNegotiable && (
                <div className="flex justify-center mt-2">
                  <p className="text-center text-white-800 text-green-500 font-bold inline-flex items-center">
                    <FaHandshake className="mr-2" />
                    Price is negotiable
                  </p>
                </div>
              )}

              {currentUserIsTheSeller ? (
                <div className="flex justify-between items-center w-full mt-3">
                  <button
                    className="btn btn-secondary flex items-center"
                    style={{ width: "calc(50% - 5px)" }}
                    onClick={() => setIsEditingProduct(true)}
                  >
                    <FaEdit className="mr-2" /> Edit Product
                  </button>
                  <div style={{ width: "10px" }} />
                  <button
                    className="btn btn-error flex items-center"
                    style={{ width: "calc(50% - 5px)" }}
                    onClick={() => setConfirmDeletion(true)}
                  >
                    <FaTrash className="mr-2" /> Delete Product
                  </button>
                </div>
              ) : (
                <div className="flex justify-between items-center w-full mt-2">
                  <button
                    className="btn btn-primary"
                    style={{ width: "calc(50% - 5px)" }}
                    onClick={updateCart}
                  >
                    {productInCart ? "Added to Cart" : "Add to Cart"}
                  </button>
                  <button
                    className="btn btn-secondary"
                    style={{ width: "calc(50% - 5px)" }}
                    onClick={() => setTakingMessage(true)}
                  >
                    Order Now
                  </button>
                </div>
              )}

              <div className="flex justify-center mt-4">
                <CopyUrlButton label="Product" />
              </div>

              <ChatButtons seller={product?.author} />

              {currentUserIsTheSeller && !user?.otherAccounts?.whatsapp && (
                <SellerWhatsAppNumberInput />
              )}

              {product?.shop?.deliveryInfo && (
                <div className="flex items-center my-8">
                  <p className="text-1xl font-bold text-white-800">
                    Product Delivery Method
                  </p>
                  <p className="text-sm">{product?.shop?.deliveryInfo}</p>
                </div>
              )}

              <div className="flex justify-around w-full border-t-[0.5px] border-b-[0.5px] mt-3">
                <section
                  className="clickable-section flex flex-col items-center w-full cursor-pointer border-t-[1px] border-b-[1px] py-2"
                  onClick={navigateToShop}
                >
                  <HiHome className="text-2xl" />
                  <p>{product.shop.name}</p>
                </section>

                <section
                  className="clickable-section flex flex-col items-center w-full cursor-pointer border-t-[1px] border-b-[1px] py-2"
                  onClick={() =>
                    navigate(`/mart/profile/${product.author._id}`)
                  }
                >
                  <HiUser className="text-2xl" />
                  <p>{product.author.name}</p>
                </section>
              </div>

              {product.activityId ? (
                <div className="flex flex-col mt-6">
                  <h2 className="text-2xl font-bold mb-3">
                    Comment on this product
                  </h2>
                  <TextArea
                    placeholder="Write your comment here..."
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    className="mt-2"
                  />
                  <button
                    className="btn btn-primary mt-2"
                    disabled={!review.length || sendingReview}
                    onClick={submitReview}
                  >
                    {sendingReview ? "Submitting..." : "Submit Comment"}
                  </button>

                  {reviewsCount > 0 && productActivity && (
                    <div className="mt-4">
                      <p className="text-lg font-semibold">
                        Customers Comments & Reviews ({reviewsCount})
                      </p>

                      {productActivity.own_reactions.review?.map((review) => (
                        <Review key={review.id} review={review} />
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <p className="text-center">
                  You cannot review this product at the moment
                </p>
              )}
            </article>
          </section>

          {renderProductListSection("Related Products", relatedProducts)}
          {renderProductListSection("Recommended Products", samePriceProducts)}
        </article>
      </section>
    </LoadingIndicator>
  );
};

const renderProductListSection = (title: string, products: Product[]) => (
  <>
    {Boolean(products.length) && (
      <p className="text-1xl mt-8 font-bold text-white-800 mb-3">{title}</p>
    )}
    <HorizontalProductList products={products} />
  </>
);

export default ProductDetailsPage;
