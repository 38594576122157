import { format } from "date-fns";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  time: string;
  name: string;
  username: string;
  id: string;
}

export default function ActorName({ time, name, username, id }: Props) {
  const timeDiff = Date.now() - new Date(time).getTime();
  const hoursBetweenDates = timeDiff / (60 * 60 * 1000);

  const isLessThan24hrs = hoursBetweenDates < 24;
  const isLessThan1hr = hoursBetweenDates < 1;

  const timeText = isLessThan1hr
    ? `${Math.floor(timeDiff / (60 * 1000))}m`
    : isLessThan24hrs
    ? `${Math.floor(hoursBetweenDates)}h`
    : format(new Date(time), "MMM d");

  return (
    <ActorLink to={`/mart/profile/${id}`}>
      <Name>{name}</Name>
      <Username>@{username}</Username>
      <Timestamp>{timeText}</Timestamp>
    </ActorLink>
  );
}

const Name = styled.span`
  color: white;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;

const ActorLink = styled(Link)`
  display: flex;
  align-items: center;

  &:hover ${Name} {
    text-decoration: underline;
  }
`;

const Username = styled.span`
  margin-left: 5px;
  color: #777;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;

const Timestamp = styled.span`
  margin-left: 15px;
  color: #777;
  position: relative;

  &::before {
    content: "";
    width: 2px;
    height: 2px;
    background-color: #777;
    position: absolute;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
