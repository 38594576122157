import { useEffect, useState } from "react";

import { Order } from "./useOrder";
import service from "../services/orders";

const useDeleteInvalidOrder = ({ _id, buyer, shop }: Order): boolean => {
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    const deleteInvalidOrders = async () => {
      const isInvalid = buyer._id === shop.author;

      setInvalid(invalid);

      if (isInvalid) await service.deleteOrder(_id);
    };

    deleteInvalidOrders();
  }, []);

  return invalid;
};

export default useDeleteInvalidOrder;
