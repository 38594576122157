import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Order } from "../../hooks/useOrder";
import { useDeleteInvalidOrder, useProducts } from "../../hooks";
import Image from "../Image";
import Modal from "../Modal";

const OrderCard = (order: Order) => {
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const { findById } = useProducts();
  const invalid = useDeleteInvalidOrder(order);
  const navigate = useNavigate();

  const { buyer, canceled, products, message, shop, status, timestamp } = order;

  const MoreDetails = (
    <div className="bg-white shadow-lg rounded-lg p-6 space-y-4">
      <h3 className="text-xl font-bold text-gray-800">Order Details</h3>
      <div className="text-sm text-gray-600">
        <p className="font-medium">
          <span className="text-gray-800">Shop Name:</span> {shop.name}
        </p>
        <p className="font-medium">
          <span className="text-gray-800">Buyer:</span> {buyer.name}
        </p>
        <p className="font-medium">
          <span className="text-gray-800">Order Status:</span>{" "}
          <span
            className={`${
              canceled ? "text-red-500" : "text-green-500"
            } font-semibold`}
          >
            {canceled ? "CANCELED" : status.label}
          </span>
        </p>
        <p className="font-medium">
          <span className="text-gray-800">Timestamp:</span>{" "}
          {new Date(timestamp).toLocaleString()}
        </p>
        {message && (
          <p className="font-medium">
            <span className="text-gray-800">Message:</span> {message}
          </p>
        )}
      </div>
      <div>
        <h4 className="text-lg font-semibold text-gray-800 mb-2">Products</h4>
        <ul className="space-y-4">
          {Object.entries(products).map(([productId, quantity]) => {
            const product = findById(productId);

            return (
              <li
                key={productId}
                className="flex items-center p-4 bg-gray-100 rounded-lg hover:shadow-md cursor-pointer"
                onClick={() => navigate(`/mart/products/${productId}`)}
              >
                <div className="w-16 h-16 flex-shrink-0">
                  <img
                    src={product?.images[0]}
                    alt="product"
                    className="w-full h-full object-cover rounded-md"
                  />
                </div>
                <div className="ml-4">
                  <p className="text-sm font-medium text-gray-800">
                    {product?.name || "Unknown Product"}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  if (invalid) return null;

  return (
    <>
      <Modal
        content={MoreDetails}
        isOpen={showMoreDetails}
        onClose={() => setShowMoreDetails(false)}
        title="More Details"
      />

      <article
        className="card bg-base-100 shadow-md cursor-pointer my-4 flex flex-col sm:flex-row justify-between items-center max-w-screen-md mx-auto"
        onClick={() => setShowMoreDetails(true)}
      >
        <div className="flex items-center space-x-4 w-full sm:w-auto">
          <figure>
            <img
              src={buyer.avatar}
              alt={buyer.name}
              className="w-24 h-24 object-cover"
            />
          </figure>
          <div className="card-body">
            <h2 className="card-title">{buyer.name}</h2>
            {message && <p className="text-sm">"{message}"</p>}
            <p>Status: {canceled ? "CANCELED" : status.label}</p>
            <p>Timestamp: {new Date(timestamp).toLocaleString()}</p>
            <p>Products count: {Object.keys(products || {}).length}</p>
          </div>
        </div>
      </article>
    </>
  );
};

export default OrderCard;
