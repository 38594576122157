import { useStreamContext } from "react-activity-feed";
import { toast } from "react-toastify";

import { Product } from "./useProducts";
import { Activity } from "../components/products/Activity";
import useUser from "./useUser";

export const PRODUCTS_COLLECTION = "products";

const useProductActivity = () => {
  const { client } = useStreamContext();
  const { user } = useUser();

  const userFeed = client?.feed("user", client.userId);

  const createProductActivity = async (product: Product) => {
    if (!client) {
      toast.error("Feed client isn't ready yet!");
      return;
    }

    const collection = await client.collections.add(
      PRODUCTS_COLLECTION,
      product._id,
      { ...product }
    );

    const time = new Date(product.timestamp).toISOString();

    return await userFeed?.addActivity({
      id: product._id,
      actor: `SU:${client.userId}`,
      verb: "post",
      object: `SO:products:${collection.id}`,
      foreign_id: client.userId + time,
      time,
    });
  };

  const checkIfHasLiked = (activity: Activity) => {
    let hasLikedSparkle = false;

    if (activity?.own_reactions?.like && user) {
      const myReaction = activity.own_reactions.like.find(
        (l) => l.user.id === user?._id
      );
      hasLikedSparkle = Boolean(myReaction);
    }

    return hasLikedSparkle;
  };

  return { createProductActivity, checkIfHasLiked };
};

export default useProductActivity;
