import { Activity } from "getstream";
import { useStreamContext } from "react-activity-feed";
import { toast } from "react-toastify";

import { Activity as AppActivity } from "../components/products/Activity";
import { PRODUCTS_COLLECTION } from "./useProductActivity";
import useNotifications from "./useNotifications";
import useUser from "./useUser";

const REACTION_KIND = "review";

export default function useReview() {
  const { createNotification } = useNotifications();
  const { user } = useUser();
  const { client } = useStreamContext();

  const createReview = async (text: string, activity: Activity) => {
    if (!activity)
      return toast.error(
        "Something went wrong! You cannot review this product right now"
      );

    const appActivity = activity as unknown as AppActivity;
    const actor = appActivity.actor;

    try {
      await client?.reactions.add(REACTION_KIND, activity, { text });

      if (actor.id !== user?._id) {
        createNotification(
          actor.id,
          REACTION_KIND,
          { text },
          `SO:${PRODUCTS_COLLECTION}:${appActivity.object.id}`
        );
      }
    } catch (error) {
      console.error("Failed to add comment reaction:", error);
    }
  };

  return { createReview };
}
