import { Avatar } from "react-activity-feed";
import styled from "styled-components";

import { Review as ReviewActivity } from "../Activity";
import ActorName from "../ActorName";

interface Props {
  review: ReviewActivity;
}

export default function Review({ review }: Props) {
  const { user } = review;

  return (
    <ReviewContainer>
      <Avatar circle image={user.data.avatar} size={40} />
      <Content>
        <ActorName
          name={user.data.name || "Unknown"}
          username={user.data.email || "Unknown"}
          id={user.id}
          time={review.created_at}
        />
        <Text>{review.data.text || "No review provided."}</Text>
      </Content>
    </ReviewContainer>
  );
}

const ReviewContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #333;
  padding: 10px 0;
`;

const Content = styled.div`
  flex: 1;
  margin-left: 10px;

  & > div {
    margin-bottom: 5px;
  }
`;

const Text = styled.p`
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;

  &::first-letter {
    text-transform: capitalize;
  }
`;
